<template>
  <v-container fluid>
    <!-- <v-container fluid> -->
    <!-- <global-FilterMenuNew
        disableStalls
        disableStallHolders
        disableTerminals
        disableDates
        disableButtons
      /> -->
    <v-row>
      <global-EventFilterMenu header='Home'/>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-card
          dark
          outlined
        >
          <v-card-title class="secondary py-1 text-subtitle-1">
            <v-icon left dense> mdi-speedometer </v-icon>
            Totals
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col lg="6" md="12" sm="6" cols="12">
                <skeletons-summary :loading="homeTotalsLoading">
                  <v-card
                    class="primary"
                    dark
                  >
                    <v-card-title class="py-1 text-subtitle-1">
                      <v-icon left> mdi-cash </v-icon>
                      Gross Sales:
                    </v-card-title>
                    <v-card-text class="headline font-weight-bold" v-if="homeTotals.hasOwnProperty('totals_data')">
                      {{ homeTotals.totals_data.grossSales !== undefined ? homeTotals.totals_data.grossSales : 'N/A' | currency }}
                    </v-card-text>
                  </v-card>
                </skeletons-summary>
              </v-col>
              <v-col lg="6" md="12" sm="6" cols="12">
                <skeletons-summary :loading="homeTotalsLoading">
                  <v-card
                    class="primary"
                    dark
                  >
                    <v-card-title class="py-1 text-subtitle-1">
                      <v-icon left> mdi-cash </v-icon>
                      Net Sales:
                    </v-card-title>
                    <v-card-text class="headline font-weight-bold" v-if="homeTotals.hasOwnProperty('totals_data')">
                      {{ homeTotals.totals_data.netSales !== undefined ? homeTotals.totals_data.netSales : 'N/A' | currency }}
                    </v-card-text>
                  </v-card>
                </skeletons-summary>
              </v-col>
              <v-col lg="6" md="12" sm="6" cols="12">
                <skeletons-summary :loading="homeTotalsLoading">
                  <v-card class="primary" dark>
                    <v-card-title class="py-1 text-subtitle-1">
                      <v-icon left> mdi-account-multiple </v-icon>
                      Headcount:
                    </v-card-title>
                    <v-card-text class="headline font-weight-bold" v-if="homeTotals.hasOwnProperty('event_metadata')">
                      {{ homeTotals.event_metadata.headcount !== undefined ? homeTotals.event_metadata.headcount : 'N/A'
                      }}
                    </v-card-text>
                  </v-card>
                </skeletons-summary>
              </v-col>
              <v-col lg="6" md="12" sm="6" cols="12">
                <skeletons-summary :loading="homeTotalsLoading">
                  <v-card
                    class="primary"
                    dark
                  >
                    <v-card-title class="py-1 text-subtitle-1">
                      <v-icon left> mdi-account-group </v-icon>
                      Spend Per Head:
                    </v-card-title>
                    <v-card-text class="headline font-weight-bold"  v-if="homeTotals.hasOwnProperty('totals_data')">
                      {{ homeTotals.totals_data.spendPerHead !== undefined ? homeTotals.totals_data.spendPerHead : 'N/A' | currency }}
                    </v-card-text>
                  </v-card>
                </skeletons-summary>
              </v-col>
              <v-col lg="6" md="12" sm="6" cols="12">
                <skeletons-summary :loading="homeTotalsLoading">
                  <v-card class="primary" dark>
                    <v-card-title class="py-1 text-subtitle-1">
                      <v-icon left> mdi-swap-horizontal </v-icon>
                      Total Transactions:
                    </v-card-title>
                    <v-card-text class="headline font-weight-bold" v-if="homeTotals.hasOwnProperty('totals_data')">
                      {{ homeTotals.totals_data.totalTransactions !== undefined ?
                  homeTotals.totals_data.totalTransactions : 'N/A' }}
                    </v-card-text>
                  </v-card>
                </skeletons-summary>
              </v-col>
              <v-col lg="6" md="12" sm="6" cols="12">
                <skeletons-summary :loading="homeTotalsLoading">
                  <v-card class="primary" dark>
                    <v-card-title class="py-1 text-subtitle-1">
                      <v-icon left> mdi-calculator </v-icon>
                      Avg Transaction Value:
                    </v-card-title>
                    <v-card-text class="headline font-weight-bold" v-if="homeTotals.hasOwnProperty('totals_data')">
                      {{ homeTotals.totals_data.avgTransactionValue !== undefined ?
                  homeTotals.totals_data.avgTransactionValue : 'N/A' | currency }}
                    </v-card-text>
                  </v-card>
                </skeletons-summary>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <v-card
          dark
          outlined
        >
          <v-card-title class="secondary py-1 text-subtitle-1">
            <v-icon left dense> mdi-podium </v-icon>
            Top 10 Stalls
          </v-card-title>
          <skeletons-table :loading="homeTotalsLoading">
            <v-card-text class="pa-0">
              <tables-simple
                :tableHeadings="topStalls.headings"
                :tableDataProperties="topStalls.dataProperties"
                :tableData="processedTopStallsData"
                :ranks="true"
              />
            </v-card-text>
          </skeletons-table>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <v-card
          dark
          outlined
        >
          <v-card-title class="secondary py-1 text-subtitle-1">
            <v-icon left dense> mdi-podium </v-icon>
            Top 10 Products
          </v-card-title>
          <skeletons-table :loading="homeTotalsLoading">
            <v-card-text class="pa-0">
              <tables-simple
                :tableHeadings="topProducts.headings"
                :tableDataProperties="topProducts.dataProperties"
                :tableData="processedTopProductsData"
                :ranks="true"
              />
            </v-card-text>
          </skeletons-table>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <v-card
          dark
          outlined
        >
          <v-card-title class="secondary py-1 text-subtitle-1">
            <v-icon left dense> mdi-podium </v-icon>
            Top 10 Terminals
          </v-card-title>
          <skeletons-table :loading="homeTotalsLoading">
            <v-card-text class="pa-0">
              <tables-simple
                :tableHeadings="topTerminals.headings"
                :tableDataProperties="topTerminals.dataProperties"
                :tableData="processedTopTerminalsData"
                :ranks="true"
              />
            </v-card-text>
          </skeletons-table>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>

// import axios from 'axios'
import {
  mapActions, mapState
} from 'vuex'

export default {
  data: () => ({
    grossSales: '',
    netSales: '',
    avgTransactionValue: '',
    spendPerHead: '',
    totalTransactions: '',
    processedTopProductsData: [],
    processedTopStallsData: [],
    processedTopTerminalsData: [],

    topStalls: {
      headings: [
        "Rank", "Stall Name", "Sales"
      ],
      dataProperties: [
        "Stall Name", "Sales"
      ]
    },
    topProducts: {
      headings: [
        "Rank", "Product Name", "Quantity", "Category", "Gross Sales"
      ],
      dataProperties: [
        "Product Name", "Quantity", "Product Name", "Gross Sales"
      ]
    },
    topTerminals: {
      headings: [
        "Rank", "Stall Name", "Terminal Number", "Terminal Sales"
      ],
      dataProperties: [
        "Stall Name", "Terminal Number", "Terminal Sales"
      ]
    },
    eventMetadata: {
      headcount: ''
    }
  }),
  computed: {
    ...mapState('dashboard', [
      'homeTotals',
      'homeTotalsLoading'
    ])
  },
  methods: {
    ...mapActions('dashboard', ['updateDashboard']), 
    
    processTopStallsData() {
    if (this.homeTotals && this.homeTotals.top_stalls_data) {
      this.processedTopStallsData = this.homeTotals.top_stalls_data.map(item => ({
        ...item,
        Sales: item.Sales !== undefined ? this.$options.filters.currency(item.Sales) : 'N/A'
      }));
    }
  }, processTopProductsData() {
      if (this.homeTotals && this.homeTotals.top_products_data) {
        this.processedTopProductsData = this.homeTotals.top_products_data.map(item => ({
          ...item,
          Quantity: item.Quantity !== undefined ? this.$options.filters.number(item.Quantity) : 'N/A',
          'Gross Sales': item['Gross Sales'] !== undefined ? this.$options.filters.currency(item['Gross Sales']) : 'N/A',
          'Net Sales': item['Net Sales'] !== undefined ? this.$options.filters.currency(item['Net Sales']) : 'N/A'
        }));
      }
    }, processTopTerminalsData() {
    if (this.homeTotals && this.homeTotals.top_terminals_data) {
      this.processedTopTerminalsData = this.homeTotals.top_terminals_data.map(item => ({
        ...item,
        'Terminal Sales': item['Terminal Sales'] !== undefined ? this.$options.filters.currency(item['Terminal Sales']) : 'N/A'
      }));
    }
  },
  },
  filters: {
    currency(value) {
      var formatter = new Intl.NumberFormat('en-AU',{
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
      return '$' + formatter.format(value);
    },
    number(value) {
      var formatter = new Intl.NumberFormat('en-AU',{
        maximumFractionDigits: 0
      })
      return formatter.format(value);
    }
  },

  watch: {
    'homeTotals.top_stalls_data': {
    handler() {
      this.processTopStallsData();
    },
    immediate: true
    },
    'homeTotals.top_products_data': {
      handler() {
        this.processTopProductsData();
      },
      immediate: true
    },
    'homeTotals.top_terminals_data': {
      handler() {
        this.processTopTerminalsData();
      },
      immediate: true
    }
  }
}

</script>

<style scoped>

body {
  background-color: #000000;
}
h1{
  color: #a09c94;
  font-family: sans-serif;
}
p{
  color: #6e6e6e;
  font-family: sans-serif;
}
</style>